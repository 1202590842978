.App {
  text-align: center;

}


@media print {
   body {
     font-size: 20pt;
     -webkit-print-color-adjust: exact;
   }

 }

 a {
  font-size: 10pt;
}
